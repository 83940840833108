
import { computed, defineComponent, onMounted, ref, reactive } from "vue";
// import DarkModeSwitcher from "@/components/dark-mode-switcher/Main.vue";
import { mapActions } from "vuex";
import CloudFun from "@cloudfun/core";
import { v1 as uuid } from "uuid";

export default defineComponent({
  components: {
    // DarkModeSwitcher,
  },
  setup() {
    const captchaToken = ref(uuid());
    const captchaUrl = computed(
      () =>
        `${process.env.VUE_APP_BACKEND_URL}/api/captcha?id=6&token=${captchaToken.value}`
    );
    const model = CloudFun.current?.model;

    const helperModal = reactive({
      showEdit: false,
      link: "https://www.cloudfun.com.tw/#contact"
    });

    const adminTitle = computed(
      () => model?.state.configuration.value.AdminTitle
    );

    onMounted(() => {
      cash("body")
        .removeClass("app")
        .removeClass("error-page")
        .addClass("login");
      cash("body")[0].click();
      // eslint-disable-next-line no-unused-expressions
      model?.dispatch("configuration/read");
    });
    return {
      adminTitle,
      helperModal,
      account: "",
      password: "",
      captcha: "",
      captchaToken,
      captchaUrl,
      rememberMe: false
    };
  },
  methods: {
    ...mapActions(["login"]),
    formSubmit() {
      this.login({
        account: this.account,
        password: this.password,
        rememberMe: this.rememberMe,
        captcha: this.captcha,
        token: this.captchaToken
      }).then(
        () => this.$router.push("/"),
        failure => {
          this.reloadCaptcha();
          CloudFun.send("warning", failure.message || "帳號或密碼錯誤");
        }
      );
    },
    reloadCaptcha() {
      this.captchaToken = uuid();
    },
    helperClick() {
      this.helperModal.showEdit = true;
    }
  }
});
